import React from 'react';
import styles from './ReturnServerModal.scss';
import FullscreenModal from '../FullscreenModal';
import PropTypes from 'prop-types';

const ReturnServerModal = ({ isOpen, onSubmit }) => {
  const handleCompleteTransaction = () => {
    onSubmit();
  };

  return (
    <FullscreenModal id="ReturnServerModal" isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>Return to your server</h1>
          <button onClick={handleCompleteTransaction} className={styles.button}>
            Continue
          </button>
        </div>
      </div>
    </FullscreenModal>
  );
};

ReturnServerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
};

export default ReturnServerModal;
