import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TimesIcon from 'components/Icons/TimesIcon';
import styles from './TipsModal.scss';
import FullscreenModal from '../FullscreenModal';
import formatPrice from 'utils/formatPrice';

const TIPS_MODES = Object.freeze({
  CURRENCY: 1,
  PERCENTAGE: 2,
});

const Keypad = ({ onKeyPress }) => {
  const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', '⌫'];

  const handleClick = value => () => onKeyPress(value);

  return (
    <div className={styles.keypad}>
      {keys.map(key => (
        <button
          key={key}
          onClick={handleClick(key)}
          className={styles.keypadButton}
        >
          {key === '⌫' ? (
            <span className={styles.keypadButtonLabel}>⌫</span>
          ) : (
            key
          )}
        </button>
      ))}
    </div>
  );
};

Keypad.propTypes = {
  onKeyPress: PropTypes.func.isRequired,
};

const TipsModal = ({
  isOpen,
  close,
  value,
  total,
  setValue,
  mode,
  setMode,
}) => {
  const [tip, setTip] = useState(value ? value.toString() : '');
  const [modeSelected, setModeSelected] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setMode(null);
      setTip(value ? value.toString() : '');
      setModeSelected(false);
    }
  }, [isOpen, value]);

  const handleKeyPress = key => {
    if (key === '⌫') {
      setTip(prev => prev.slice(0, -1));
    } else if (key === '.') {
      if (!tip.includes('.')) {
        setTip(prev => (prev === '' ? '0.' : `${prev}.`));
      }
    } else if (/^\d$/.test(key)) {
      const newValue = tip + key;
      if (/^\d+(\.\d{0,2})?$/.test(newValue)) {
        setTip(newValue);
      }
    }
  };

  const handleConfirm = () => {
    const numericTip = parseFloat(tip || '0');
    setValue(numericTip);
    close();
  };

  const setCurrencyMode = () => {
    setMode(TIPS_MODES.CURRENCY);
    setModeSelected(true);
  };

  const setPercentageMode = () => {
    setMode(TIPS_MODES.PERCENTAGE);
    setModeSelected(true);
  };

  return (
    <FullscreenModal id="TipsModal" isOpen={isOpen} onClose={close}>
      <div className={styles.root}>
        <div className={styles.close}>
          <button type="button" onClick={close}>
            <TimesIcon width="28" height="26" />
          </button>
        </div>
        <div className={styles.total}>
          <div className={styles.totalPriceText}>
            How much would you like to tip?
          </div>
          <div className={styles.totalPrice}>
            Product Total: ${formatPrice(total)} CAD
          </div>
        </div>
        {!modeSelected && (
          <div className={styles.customTipsSelect}>
            <button
              type="button"
              className={clsx(styles.btn, {
                [styles.btnActive]: mode === TIPS_MODES.CURRENCY,
              })}
              onClick={setCurrencyMode}
            >
              $
            </button>
            <button
              type="button"
              className={clsx(styles.btn, {
                [styles.btnActive]: mode === TIPS_MODES.PERCENTAGE,
              })}
              onClick={setPercentageMode}
            >
              %
            </button>
          </div>
        )}
        {modeSelected && (
          <>
            <div className={styles.tipDisplay}>
              {mode === TIPS_MODES.PERCENTAGE
                ? `Tip: ${tip || '0'}%`
                : `Tip: $${tip || '0'}`}
            </div>
            <div className={styles.keypadContainer}>
              <Keypad onKeyPress={handleKeyPress} />
            </div>
            <div className={styles.totalWithTipContainer}>
              <div className={styles.totalWithTip}>
                {mode === TIPS_MODES.PERCENTAGE
                  ? `Total With Tip: $${formatPrice(
                      total + (total * parseFloat(tip || '0')) / 100
                    )}`
                  : `Total With Tip: $${formatPrice(
                      total + parseFloat(tip || '0')
                    )}`}
              </div>
            </div>
            <div className={styles.confirmButtonContainer}>
              <button
                type="button"
                className={styles.confirmButton}
                onClick={handleConfirm}
              >
                Confirm Tip
              </button>
            </div>
          </>
        )}
      </div>
    </FullscreenModal>
  );
};

TipsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  mode: PropTypes.number,
  setMode: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default TipsModal;
