import React, { useState, useCallback, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { Spinner } from 'react-activity';

import VisaIcon from 'components/Icons/VisaIcon';
import MastercardIcon from 'components/Icons/MastercardIcon';
import DiscoverIcon from 'components/Icons/DiscoverIcon';
import AmericanExpressIcon from 'components/Icons/AmericanExpressIcon';
import InputLabelled from 'components/InputLabelled';
import SelectLabelled from 'components/SelectLabelled';
import ArrowRightIcon from 'components/Icons/ArrowRightIcon';
import CheckoutContext from 'pages/Checkout/context';

import { stripeActions } from 'models/stripe/slice';
import { isLoadingSelector } from 'models/stripe/selectors';

import { toast } from 'react-toastify';
import strings from 'constants/strings';
import styles from './PaymentCard.scss';
import { nextMonth, monthsSelect, yearsSelect } from './constants';
import SpinnerModal from '../../components/Modal/SpinnerModal';

const PaymentCard = () => {
  const { paidOrdersList, handleCancelCheckout } = useContext(CheckoutContext);

  const [cvc, setCvc] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [year, setYear] = useState(yearsSelect[0]);
  const [month, setMonth] = useState(nextMonth);

  const confirmCardPayment = useAction(stripeActions.confirmCardPayment);
  const isLoading = useSelector(isLoadingSelector);

  const handleSubmit = useCallback(() => {
    if (isLoading) return;
    if (
      cardNumber.trim() === '' ||
      cardHolder.trim() === '' ||
      cvc.trim() === ''
    ) {
      toast.error(strings.fillAllFields, {
        progressClassName: 'customProgressBarError',
      });
      return;
    }

    confirmCardPayment({
      paidOrdersList,
      pmData: JSON.stringify({
        card: {
          number: cardNumber,
          exp_month: month.value + 1,
          exp_year: year.label,
          cvc,
        },
        billing_details: {
          name: cardHolder,
        },
      }),
    });
  }, [cardNumber, month, year, cvc, cardHolder, confirmCardPayment, isLoading]);

  return (
    <CSSTransition in appear timeout={200} classNames="fade" unmountOnExit>
      <div className={styles.root}>
        <SpinnerModal isOpen={isLoading} />
        <div className={styles.group} style={{ marginTop: 60 }}>
          <InputLabelled
            value={cardHolder}
            label="Name on card"
            placeholder="Card holder"
            onChange={e => setCardHolder(e.target.value)}
          />
        </div>

        <div className={styles.group}>
          <InputLabelled
            maxLength="16"
            value={cardNumber}
            placeholder="Card number"
            label="Enter debit or credit card number"
            onChange={e => setCardNumber(e.target.value)}
          />

          <div className={styles.merchants}>
            <div>
              <VisaIcon width="36" />
            </div>
            <div>
              <MastercardIcon width="36" />
            </div>
            <div>
              <DiscoverIcon width="36" />
            </div>
            <div>
              <AmericanExpressIcon width="36" />
            </div>
          </div>
        </div>

        <div className={styles.meta}>
          <div>
            <SelectLabelled
              label="Month"
              value={month}
              onChange={setMonth}
              items={monthsSelect}
            />
          </div>

          <div>
            <SelectLabelled
              label="Year"
              value={year}
              onChange={setYear}
              items={yearsSelect}
            />
          </div>

          <div>
            <InputLabelled
              value={cvc}
              maxLength="4"
              label="Security Code"
              onChange={e => setCvc(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.actions}>
          <div className={styles.confirm}>
            <button
              className={styles.confirmLink}
              onClick={handleCancelCheckout}
            >
              Cancel
            </button>
          </div>
          <div className={styles.proceed}>
            <button className={styles.btnProceed} onClick={handleSubmit}>
              {isLoading ? <Spinner /> : <ArrowRightIcon />}
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default PaymentCard;
