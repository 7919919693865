/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-return-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ArrowRightIcon from 'components/Icons/ArrowRightIcon';
import AnimatedLoadingIcon from 'components/Icons/AnimatedLoadingIcon';
import { isEmpty } from 'lodash';
import styles from './KeyboardInput.scss';

class KeyboardInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
    };
  }

  componentDidMount() {
    this.setState({ value: this.props.value || '' });
    if (this.props.autoOpenOnMount && this.input) {
      this.input.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value || '' });
    }
  }

  handleInputChange = e => {
    const { value } = e.target;
    const { pattern, onChange } = this.props;

    const isMatch = pattern ? value.match(pattern) : true;
    if (!pattern || isMatch || value === '') {
      this.setState({ value }, () => {
        if (onChange) {
          onChange(value);
        }
      });
    }
  };

  setInputRef = input => {
    this.input = input;
  };

  showArrow = () => {
    return this.state.value ? <ArrowRightIcon /> : null;
  };

  render() {
    const { value } = this.state;
    const { handleInputChange } = this;
    const {
      className,
      name,
      type,
      placeholder,
      loading,
      disabled,
      caption,
      textCentered,
      buttonOptions,
      maxLength,
      mode,
      pattern,
    } = this.props;

    return (
      <div
        className={clsx(
          mode ? styles.root : styles.rootLineDisabled,
          className
        )}
      >
        <div className={styles.caption}>{caption}</div>
        <div className={styles.input}>
          <input
            ref={this.setInputRef}
            name={name}
            type={type}
            value={value}
            onFocus={() => {}}
            onChange={handleInputChange}
            placeholder={placeholder}
            autoComplete="off"
            maxLength={maxLength}
            disabled={disabled}
            pattern={pattern}
            className={clsx({ [styles.inputCentered]: textCentered })}
            onKeyDown={this.props.onKeyDown}
          />
        </div>
        {isEmpty(buttonOptions) ? (
          <div className={styles.proceed}>
            <button
              type="submit"
              className={styles.proceedBtn}
              disabled={isEmpty(value)}
            >
              {loading ? (
                <AnimatedLoadingIcon width="75" height="75" />
              ) : (
                this.showArrow()
              )}
            </button>
          </div>
        ) : (
          <div className={buttonOptions.containerClassName}>
            {buttonOptions.options.map(option => {
              if (option.type === 'submit') {
                return (
                  <button
                    type={option.type}
                    className={option.className}
                    disabled={isEmpty(value)}
                  >
                    {option.name}
                  </button>
                );
              }
              return (
                <button
                  type={option.type}
                  className={option.className}
                  onClick={evt => {
                    evt.preventDefault();
                    option.action();
                  }}
                >
                  {option.name}
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

KeyboardInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  caption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  autoOpenOnMount: PropTypes.bool,
  buttonOptions: PropTypes.array,
  textCentered: PropTypes.bool,
  maxLength: PropTypes.number,
  pattern: PropTypes.string,
  mode: PropTypes.string,
  onKeyDown: PropTypes.func,
};

KeyboardInput.defaultProps = {
  className: '',
  disabled: false,
  value: '',
  textCentered: false,
  maxLength: null,
  pattern: null,
  autoOpenOnMount: false,
  caption: '',
  loading: false,
  mode: '',
};

export default KeyboardInput;
