import React, { useContext, useState } from 'react';
import styles from './Checkout.scss';
import VisaIcon from 'components/Icons/VisaIcon';
import MastercardIcon from 'components/Icons/MastercardIcon';
import DiscoverIcon from 'components/Icons/DiscoverIcon';
import AmericanExpressIcon from 'components/Icons/AmericanExpressIcon';
import CheckoutContext from 'pages/Checkout/context';
import formatPrice from 'utils/formatPrice';
import { PAYMENT_METHODS } from 'pages/Checkout/constants';

import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';

import { desksActions } from 'models/desks/slice';
import {
  receiptEmailSelector,
  numberOfGuestsSelector,
} from 'models/desks/selectors';
import ReceiptEmailModal from 'components/Modal/ReceiptEmailModal';
import { acquirerSelector } from 'models/session/selectors';
import Button from './Button';
import PropTypes from 'prop-types';

const HeaderIcons = () => (
  <>
    <VisaIcon className={styles.icon} width={31} height={11} fill="#EDEAE3" />
    <MastercardIcon
      className={styles.icon}
      width={17}
      height={10}
      fill="#EDEAE3"
    />
    <DiscoverIcon className={styles.icon} fill="#EDEAE3" />
    <AmericanExpressIcon className={styles.icon} fill="#EDEAE3" />
  </>
);

const Checkout = ({ selectedTip, changePaymentMethod, checkoutData }) => {
  // Context
  const { paidOrdersList, selectedPaymentMethod } = useContext(CheckoutContext);

  // Selectors
  const receiptEmail = useSelector(receiptEmailSelector);
  const hostAcquirer = useSelector(acquirerSelector);
  const numberOfGuests = useSelector(state =>
    numberOfGuestsSelector(state, paidOrdersList[0])
  );

  // useStates
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [count, setCount] = useState(numberOfGuests || 0);
  const [isInputFocused, setInputFocused] = useState(false);
  const [showGuestErrorMessage, setShowGuestErrorMessage] = useState(false);

  // Actions
  const setReceiptEmail = useAction(desksActions.changeReceiptEmail);
  const updateNumberOfGuests = useAction(desksActions.updateOrderRequest);

  // Functions
  const closeEmailModal = () => {
    setEmailModalOpen(false);
  };
  const openEmailModal = () => {
    setEmailModalOpen(true);
  };

  // Handlers
  const handleGuestEmailSubmit = email => {
    setEmailModalOpen(false);
    setReceiptEmail({ id: paidOrdersList[0], email });
  };

  const handleCountChange = e => {
    const newCount = Math.max(0, parseInt(e.target.value, 10) || 0);
    setCount(newCount);
    updateNumberOfGuests({
      id: paidOrdersList[0],
      num_of_guests: newCount,
      skip_loading: true,
    });
  };

  const handlePayment = method => {
    if (count <= 0) {
      setShowGuestErrorMessage(true); // Set to true when payment is attempted with 0 guests
    } else {
      changePaymentMethod(method);
    }
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
    if (count === 0) {
      setCount(0); // Reset to 0 if the input is empty or invalid
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.total}>
        <p className={styles.title}>Your Total</p>
        <p className={styles.price}>
          $ {formatPrice(checkoutData.total_price + selectedTip)}
          <span className={styles.priceCurrency}>CAD</span>
        </p>
        <div className={styles.customerInfoWrapper}>
          <div className={styles.customerInfo}>
            <p className={styles.blockTitle}>*Number of Guests?</p>
            <div className={styles.cartButtonsWrapper}>
              <button
                className={styles.cartButton}
                onClick={() => {
                  setCount(prevCount => {
                    const newCount = prevCount - 1;
                    updateNumberOfGuests({
                      id: paidOrdersList[0],
                      num_of_guests: newCount,
                      skip_loading: true,
                    });
                    return newCount;
                  });
                }}
                disabled={count === 0}
              >
                -
              </button>
              <input
                type="number"
                value={isInputFocused && count === 0 ? '' : count}
                onChange={handleCountChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                className={styles.countInput}
                min="0"
              />
              <button
                className={styles.cartButton}
                onClick={() => {
                  setCount(prevCount => {
                    const newCount = prevCount + 1;
                    updateNumberOfGuests({
                      id: paidOrdersList[0],
                      num_of_guests: newCount,
                      skip_loading: true,
                    });
                    return newCount;
                  });
                }}
              >
                +
              </button>
            </div>
            {showGuestErrorMessage && count <= 0 && (
              <p className={styles.errorMessage}>
                *Please enter the number of guests
              </p>
            )}
          </div>
          <div className={styles.customerInfo}>
            <div className={styles.block}>
              <p className={styles.blockTitle}>Receipt will be emailed to:</p>
              <div className={styles.email}>
                <div className={styles.emailInner} onClick={openEmailModal}>
                  <p className={styles.emailCaption}>
                    {receiptEmail || 'Enter your email address'}
                  </p>
                </div>
              </div>
              <ReceiptEmailModal
                isOpen={isEmailModalOpen}
                onSubmit={handleGuestEmailSubmit}
                close={closeEmailModal}
                email={receiptEmail}
              />
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <p className={styles.blockTitle}>How would you like to pay?</p>
          <div className={styles.cards}>
            <Button
              className={styles.paymentButton}
              title="Cash"
              header={<p>CAD / USD</p>}
              handlePayment={() => handlePayment(PAYMENT_METHODS.CASH)}
              active={selectedPaymentMethod === PAYMENT_METHODS.CASH}
            />
            <Button
              className={styles.paymentButton}
              title="Debit / Credit"
              active={selectedPaymentMethod === PAYMENT_METHODS.DEBIT}
              header={<HeaderIcons />}
              handlePayment={() => handlePayment(PAYMENT_METHODS.DEBIT)}
              disabled={
                checkoutData.total_price < 0.5 ||
                !hostAcquirer?.acquirer ||
                !hostAcquirer?.acquirer_id
              }
            />
            <Button
              className={styles.paymentButton}
              title="Alternative Payment"
              header="Manual Entry"
              handlePayment={() => handlePayment(PAYMENT_METHODS.ALTERNATIVE)}
              active={selectedPaymentMethod === PAYMENT_METHODS.ALTERNATIVE}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Checkout.propTypes = {
  selectedTip: PropTypes.number,
  changePaymentMethod: PropTypes.func,
  checkoutData: PropTypes.object,
};

export default Checkout;
